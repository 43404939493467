import React, { useState, useEffect } from 'react';



const NewsletterPopup = ({data}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPopupOpen(true);
    }, 1000); 

    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Feliratkozás:', email);
    closePopup(); 
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      closePopup();
    }
  };

  return (
    <>
      {isPopupOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleBackdropClick} 
        >
          <div className="bg-black rounded-lg p-3 shadow-lg max-w-sm w-full relative">
            
            <button
              onClick={closePopup}
              className="absolute top-1 right-2 text-gray-400 hover:text-gray-700"
              aria-label="Bezár"
            >
              &times;
            </button>
          
            <h2 className="text-xl text-gray-100 mb-4">{data.footer6}</h2>
            <h2 className="text-sm text-gray-100 mb-4">{data.footer7}</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="E-mail "
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="border border-gray-300 rounded-md p-2 mb-4 w-full"
              />
              <button
                type="submit"
                className="w-full bg-red-500 text-white rounded-md p-2 hover:bg-red-600"
              >
                {data.footer5}
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default NewsletterPopup;
